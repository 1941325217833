/* =============================================================================
   App specific CSS file.
   ========================================================================== */

/* universal */

.navbar-nav li a, .navbar-nav li p {
  line-height: 50px;
}

.navbar-brand {
  line-height: 50px;
}

html {
  overflow-y: scroll;
}

section {
  overflow: auto;
}

textarea {
  resize: vertical;
}

.container-narrow{
  margin: 0 auto;
  max-width: 700px;
}

/* Forms */

.navbar-form input[type="text"],
.navbar-form input[type="password"] {
  width: 180px;
}


.form-register {
  width: 50%;
}

.form-register .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/* footer */

div.container {
    padding-bottom: 92px;
}

footer {
  margin-top: 45px;
  padding-top: 5px;
  border-top: 1px solid #eaeaea;
  color: #999999;
}

footer a {
  color: #999999;
}

footer p {
  float: right;
  margin-right: 25px;
}

footer ul {
  list-style: none;
}

footer ul li {
  float: left;
  margin-left: 10px;
}

footer .company {
  float: left;
  margin-left: 25px;
}

footer .footer-nav {
  float: right;
  margin-right: 25px;
  list-style: none;
}
